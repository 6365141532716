import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Heading, TextInput } from '@loomispay/vault';
import { Modal } from 'components/Modal';
import { FormSelect } from 'components/Select';
import { FormTextInput } from 'components/TextInput';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CloseButton, FlexDiv, FlexLeftCol, FlexRightCol, Form } from 'routes/fees/helper';
import { fetchFromBackend } from 'utils/fetch';
import { createToast } from 'vercel-toast';
import { z } from 'zod';
import { ElasticMerchant, EventBankAccountType, toEventBankAccountType } from '../../../api/types';

const bankAccountTypeInput = {
  INTERNATIONAL_BANK_ACCOUNT: {
    bankIdentificationCodeLabel: 'BIC',
    bankAccountNumberLabel: 'IBAN',
  },
  SWEDISH_BANK_ACCOUNT: {
    bankIdentificationCodeLabel: 'Clearing number',
    bankAccountNumberLabel: 'Account number',
  },
  NORWEGIAN_BANK_ACCOUNT: {
    bankIdentificationCodeLabel: 'Bank code',
    bankAccountNumberLabel: 'Account number',
  },
};

interface CreateBankAccountInformationUpdateRequest {
  bankAccountType: EventBankAccountType;
  accountOwner: string;
  bankIdentificationCode: string;
  bankAccountNumber: string;
  salesforceTicketId: string;
}

export const UpdateBankAccountInformation = (props: { elasticMerchant: ElasticMerchant }) => {
  const { t } = useTranslation();
  const bankDetails = props.elasticMerchant.merchant.bankAccount;
  const merchantId = props.elasticMerchant.merchant.id;
  const [loadModal, setLoadModal] = useState<boolean>(false);

  const FormInputSchema = z.object({
    accountOwner: z.string().min(1).max(200),
    bankAccountType: z.enum([
      EventBankAccountType.INTERNATIONAL_BANK_ACCOUNT,
      EventBankAccountType.SWEDISH_BANK_ACCOUNT,
      EventBankAccountType.NORWEGIAN_BANK_ACCOUNT,
    ]),
    bankIdentificationNumber: z.string().refine(value => {
      const bankAccountType = watch('bankAccountType');
      if (bankAccountType === EventBankAccountType.INTERNATIONAL_BANK_ACCOUNT) {
        return /^[A-Z]{6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3})?$/.test(value);
      } else if (bankAccountType === EventBankAccountType.SWEDISH_BANK_ACCOUNT) {
        return /^[0-9]{4,5}$/.test(value);
      } else if (bankAccountType === EventBankAccountType.NORWEGIAN_BANK_ACCOUNT) {
        return /^[0-9]{4}$/.test(value);
      } else return false;
    }),
    bankAccountNumber: z.string().refine(value => {
      const bankAccountType = watch('bankAccountType');
      if (bankAccountType === EventBankAccountType.INTERNATIONAL_BANK_ACCOUNT) {
        return /^[A-Z]{2}[0-9]{2}[a-zA-Z0-9]{1,30}$/.test(value);
      } else if (bankAccountType === EventBankAccountType.SWEDISH_BANK_ACCOUNT) {
        return /^[0-9]{7,10}$/.test(value);
      } else if (bankAccountType === EventBankAccountType.NORWEGIAN_BANK_ACCOUNT) {
        return /^[0-9]{7}$/.test(value);
      } else return false;
    }),
    salesforceTicketId: z.string().refine(value => {
      return /^[0-9]{5}[ 0-9]{0,59}$/.test(value);
    }),
  });

  type FormInputSchemaType = z.infer<typeof FormInputSchema>;

  const { control, handleSubmit, watch } = useForm<FormInputSchemaType>({
    defaultValues: {
      bankAccountType: bankDetails?.type
        ? toEventBankAccountType[bankDetails.type]
        : EventBankAccountType.SWEDISH_BANK_ACCOUNT,
      bankIdentificationNumber: bankDetails?.bankCode ?? '',
      bankAccountNumber: bankDetails?.accountNumber ?? '',
      accountOwner: bankDetails?.owner ?? '',
      salesforceTicketId: '',
    },
    resolver: zodResolver(FormInputSchema),
  });

  const bankAccountTypeLabel = {
    [EventBankAccountType.INTERNATIONAL_BANK_ACCOUNT]: t('merchant.bank-details.international'),
    [EventBankAccountType.SWEDISH_BANK_ACCOUNT]: t('merchant.bank-details.swedish'),
    [EventBankAccountType.NORWEGIAN_BANK_ACCOUNT]: t('merchant.bank-details.norwegian'),
  };

  const bankAccountTypeOptions = Object.values(EventBankAccountType).map(it => ({
    value: it,
    label: <div>{bankAccountTypeLabel[it]}</div>,
  }));

  const onSubmit = async (data: FormInputSchemaType) => {
    const reqBody: CreateBankAccountInformationUpdateRequest = {
      bankAccountType: data.bankAccountType,
      accountOwner: data.accountOwner,
      bankIdentificationCode: data.bankIdentificationNumber,
      bankAccountNumber: data.bankAccountNumber,
      salesforceTicketId: data.salesforceTicketId,
    };
    const response = await fetchFromBackend(`/merchants/${merchantId}/bank-account-information-update-request`, {
      method: 'POST',
      body: JSON.stringify(reqBody),
    });
    if (response.ok) {
      setLoadModal(false);
      createToast(t('merchant.bank-details.v1.request.sent'), {
        timeout: 10000,
        type: 'success',
      });
    } else if (response.status > 500)
      createToast(t('unexpected-error'), {
        timeout: 6000,
        type: 'error',
      });
    else if (response.status >= 400)
      createToast(t('bad-data-format'), {
        timeout: 6000,
        type: 'error',
      });
  };

  const accountType = watch('bankAccountType') ?? EventBankAccountType.SWEDISH_BANK_ACCOUNT;

  return (
    <div>
      <div
        style={{
          position: 'absolute',
          top: '-50px',
          left: '140px',
        }}
      >
        <Button
          size={'s'}
          variant={'tertiary'}
          icon={'edit'}
          onClick={() => {
            setLoadModal(true);
          }}
        />
      </div>

      <Modal isOpen={loadModal} close={() => setLoadModal(false)}>
        <Heading size={'m'}>{t('merchant.bank-details.edit-bank-details')}</Heading>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FlexDiv>
            <FlexLeftCol>
              <FormSelect
                label={t('merchant.bank-details.bank-account-type')}
                name={'bankAccountType'}
                options={bankAccountTypeOptions}
                control={control}
              />
            </FlexLeftCol>
            <FlexRightCol>
              <TextInput
                id={'currency'}
                name="currency"
                label={t('merchant.bank-details.currency')}
                value={bankDetails?.currency ?? ''}
                readOnly={true}
              />
            </FlexRightCol>
          </FlexDiv>
          <FlexDiv>
            <FlexLeftCol>
              <FormTextInput name="accountOwner" control={control} label={t('merchant.bank-details.account-owner')} />
            </FlexLeftCol>
            <FlexRightCol>
              <FormTextInput
                name="salesforceTicketId"
                control={control}
                label={t('merchant.bank-details.salesforce-ticket-id')}
              />
            </FlexRightCol>
          </FlexDiv>
          <FlexDiv>
            <FlexLeftCol>
              <FormTextInput
                name="bankIdentificationNumber"
                control={control}
                label={bankAccountTypeInput[EventBankAccountType[accountType]].bankIdentificationCodeLabel}
              />
            </FlexLeftCol>
          </FlexDiv>
          <FlexDiv>
            <FlexLeftCol>
              <FormTextInput
                name="bankAccountNumber"
                control={control}
                label={bankAccountTypeInput[EventBankAccountType[accountType]].bankAccountNumberLabel}
              />
            </FlexLeftCol>
          </FlexDiv>
          <div style={{ float: 'right' }}>
            <Button type={'submit'} label={t('merchant.bank-details.save-changes')} />
          </div>
          <CloseButton>
            <Button
              label={t('merchant.bank-details.cancel')}
              variant={'tertiary'}
              onClick={() => {
                setLoadModal(false);
              }}
            />
          </CloseButton>
        </Form>
      </Modal>
    </div>
  );
};
