import { Heading, Table } from '@loomispay/vault';
import { Badge } from 'components/Badge';
import { ContentWrapper } from 'components/ContentWrapper';
import { Merchant } from 'components/Merchant';
import StatusIndicator from 'components/StatusIndicator';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { formatISODateTime } from 'utils/formatters/dateFormatters';
import { AcquirerOnboardingFailedModal } from './AcquirerOnboardingFailedModal';
import { SupportBackofficeSelect } from '../../components/Select';
import { fetchFromBackend } from '../../utils/fetch';
import { createToast } from 'vercel-toast';
import styled from 'styled-components';

export interface RequireAttentionMerchantsDTO extends Record<string, unknown> {
  merchantId: string;
  status: AttentionStatus;
  reason?: string;
  updatedAt: string;
  rejectedPayments: number;
  country: string;
}

enum AttentionStatus {
  PAYOUTS_PAUSED = 'PAYOUTS_PAUSED',
  ACQUIRER_ONBOARDING_FAILED = 'ACQUIRER_ONBOARDING_FAILED',
}

interface MerchantStatusesResponse {
  requireAttentionMerchantDTOs?: RequireAttentionMerchantsDTO[];
  isError: boolean;
  refresh: () => void;
}

export enum CountryCode {
  DK = 'DK',
  SE = 'SE',
  ES = 'ES',
  NO = 'NO',
}

const countryCodeLabel = {
  [CountryCode.SE]: 'Sweden',
  [CountryCode.DK]: 'Denmark',
  [CountryCode.ES]: 'Spain',
  [CountryCode.NO]: 'Norway',
};

const countryOptions = Object.values(CountryCode).map(it => ({
  value: it,
  label: countryCodeLabel[it],
}));

export const MerchantStatusScreen = () => {
  const [requireAttentionMerchants, setRequireAttentionMerchants] = useState<RequireAttentionMerchantsDTO[]>();
  const { t } = useTranslation();
  const tableData: RequireAttentionMerchantsDTO[] = requireAttentionMerchants ?? [];
  const [countryCode, setCountryCode] = useState<CountryCode | null>();

  const statusToText = {
    [AttentionStatus.PAYOUTS_PAUSED]: t('merchant.status.table.status.paused'),
    [AttentionStatus.ACQUIRER_ONBOARDING_FAILED]: t('merchant.status.table.status.stuck'),
  };

  const [selectedMerchantId, setSelectedMerchantId] = useState<string | null>(null);
  const history = useHistory();

  const fetchRequireAttentionMerchants = async () => {
    const url = '/merchants/status/require-attention' + (countryCode != null ? `?country=${countryCode}` : '');
    await fetchFromBackend(url).then(async response => {
      if (response.ok) {
        const responseBody: MerchantStatusesResponse = await response.json();
        setRequireAttentionMerchants(responseBody.requireAttentionMerchantDTOs);
      } else {
        createToast(t('payout-status.error-unknown'), {
          timeout: 6000,
          type: 'error',
        });
      }
    });
  };

  useEffect(() => {
    fetchRequireAttentionMerchants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  const columns = [
    {
      Header: t('payout-status.table.merchant'),
      accessor: 'merchantId',
      width: 70,
      Cell: (props: { value: string }) => <Merchant merchantId={props.value} clickable={false} />,
    },
    {
      Header: t('merchant.status.need-attention.country'),
      accessor: 'country',
      width: 50,
    },
    {
      Header: t('merchant.status.table.status'),
      accessor: 'status',
      width: 80,
      Cell: (props: { value: AttentionStatus }) => {
        return (
          <StatusIndicator
            variant={props.value === AttentionStatus.PAYOUTS_PAUSED ? 'neutral' : 'warning'}
            text={statusToText[props.value]}
          />
        );
      },
    },
    { Header: t('payout-status.table.reason'), accessor: 'reason' },
    {
      Header: t('payout-status.table.since'),
      width: 50,
      accessor: 'updatedAt',
      Cell: (props: { value: string }) => {
        return formatISODateTime(props.value);
      },
    },
  ];

  return (
    <ContentWrapper>
      <FlexDiv>
        <LeftCol>
          <Heading size="m" color={'primary'}>
            {t('merchant.status.need-attention')}
            <Badge
              color={tableData.length === 0 ? 'positive' : 'negative'}
              background={tableData.length === 0 ? 'positiveBg' : 'negativeBg'}
            >
              {tableData.length}
            </Badge>
          </Heading>
        </LeftCol>
        <RightCol>
          <SupportBackofficeSelect
            options={[{ value: undefined, label: 'All' }, ...countryOptions]}
            onSelectionChange={value => setCountryCode(value as CountryCode)}
            label={t('merchant.status.need-attention.country')}
          />
        </RightCol>
      </FlexDiv>
      {tableData.length > 0 && (
        <>
          <Table
            columns={columns}
            data={tableData}
            getRowProps={row => ({
              onClick: () => {
                if (row.values.status === AttentionStatus.ACQUIRER_ONBOARDING_FAILED) {
                  setSelectedMerchantId(row.values.merchantId);
                } else if (row.values.status === AttentionStatus.PAYOUTS_PAUSED)
                  history.push({
                    pathname: `/merchant/${row.values.merchantId}`,
                  });
              },
            })}
          />
          <AcquirerOnboardingFailedModal
            selectedMerchantId={selectedMerchantId}
            closeModal={() => setSelectedMerchantId(null)}
            merchantsNeedingAttention={requireAttentionMerchants}
          />
        </>
      )}
    </ContentWrapper>
  );
};

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
`;

const LeftCol = styled.div`
  width: 85%;
`;

const RightCol = styled.div`
  width: 15%;
`;
