import { fetchByUrl } from 'api';
import useSWR from 'swr';
import { toUrl } from 'utils/formatters/toUrl';

interface FileResponse {
  files?: File[];
  isError: boolean;
  refresh: () => void;
}

interface AmexFileResponse {
  files?: AmexFile[];
  isError: boolean;
  refresh: () => void;
}

export enum FileStatus {
  RECEIVED = 'RECEIVED',
  FAILED = 'FAILED',
  SKIPPED = 'SKIPPED',
  SUCCEEDED = 'SUCCEEDED',
  INITIALLY_PARSED = 'INITIALLY_PARSED',
}

export enum SourceParty {
  CREDORAX = 'CREDORAX',
  AMEX_INITIAL_PARSING = 'AMEX_INITIAL_PARSING',
  AMEX_SETTLEMENT_TRIGGERED = 'AMEX_SETTLEMENT_TRIGGERED',
}

export interface File extends Record<string, unknown> {
  id: string;
  name: string;
  status: FileStatus;
  receivedAt: string;
  type: string;
  needsAttention: boolean;
  kibanaUrl: string;
  retry: boolean;
  isAvailable: boolean;
  sourceParty: SourceParty;
}

export interface AmexFile extends File {
  uniquePaymentReferenceNumber: string | null;
  isEmpty: boolean | null;
  triggered: boolean;
  paymentNetAmount: string | null;
  paymentCurrency: string | null;
  processedAt: string | null;
}

export enum FileType {
  MERCHANT_SETTLEMENT_STATEMENT = 'MERCHANT_SETTLEMENT_STATEMENT',
}

export enum FileDownloadTabs {
  SHIFT4_SETTLEMENTS = 'SHIFT4_SETTLEMENTS',
  AMEX_SETTLEMENTS = 'AMEX_SETTLEMENTS',
}

export const GetAllFilesResponse = (type: FileType, startDate: Date | null, endDate: Date | null): FileResponse => {
  const start = startDate?.toISOString();
  const end = endDate?.toISOString();
  const { error, data, mutate } = useSWR<File[]>(
    [
      toUrl('/file-processing', [
        ['type', type],
        ['start', start],
        ['end', end],
      ]),
    ],
    url => fetchByUrl(url),
    { refreshInterval: 10000 }
  );

  return {
    files: data,
    isError: error,
    refresh: mutate,
  };
};

export const GetAllAmexFilesResponse = (startDate: Date | null, endDate: Date | null): AmexFileResponse => {
  const start = startDate?.toISOString();
  const end = endDate?.toISOString();
  const { error, data, mutate } = useSWR<AmexFile[]>(
    [
      toUrl('/file-processing/amex', [
        ['start', start],
        ['end', end],
      ]),
    ],
    url => fetchByUrl(url),
    { refreshInterval: 10000 }
  );

  return {
    files: data,
    isError: error,
    refresh: mutate,
  };
};
