import {
  MediumIcons,
  NavigationBar,
  NavigationButton,
  NavigationChild,
  NavigationContextItem,
  NavigationContextMenu,
  NavigationHeader,
  NavigationItem,
  NavigationSeparator,
  NavigationTree,
} from '@loomispay/vault';
import { hasAnyPermission, Permission, useUserPermissions } from 'permissions';
import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { logOut } from '../App';
import { useFeesMenuItems } from './fees/feesMenuItems';
import { useFinanceMenuItems } from './finance/financeMenuItems';
import { SESSION_STORAGE_QUERY_KEY } from './merchants/SearchScreen';

const ProfileImage = styled.div`
  width: 32px;
  height: 32px;
  background-color: #ffeffe;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
`;

const clearSearchSessionStorage = () => {
  try {
    sessionStorage.removeItem(SESSION_STORAGE_QUERY_KEY);
  } catch (e) {
    console.error(e);
  }
};

const Fixed = styled.div`
  position: fixed;
`;

export interface MenuItem {
  id: string;
  href?: string;
  title: string;
  icon: MediumIcons;
  subMenuItems?: SubMenuItem[];
}

export interface SubMenuItem {
  id: string;
  href?: string;
  title: string;
}

const useOtherNavigationItems = () => {
  const { t } = useTranslation();
  const { permissions } = useUserPermissions();
  const items: MenuItem[] = [];

  if (hasAnyPermission(permissions, [Permission.READ_MERCHANT])) {
    items.push({
      id: 'home',
      href: '/home',
      title: t('navigation.home'),
      icon: 'tables',
    });
    items.push({
      id: 'merchant',
      href: '/merchant',
      title: t('navigation.merchant-search'),
      icon: 'contract',
    });
  }

  if (hasAnyPermission(permissions, [Permission.READ_FILE_PROCESSING, Permission.WRITE_FILE_PROCESSING])) {
    items.push({
      id: 'file-processing',
      href: '/file-processing',
      title: t('navigation.file-processing'),
      icon: 'documents',
    });
  }

  if (hasAnyPermission(permissions, [Permission.READ_BANK_ACCOUNT_INFORMATION])) {
    items.push({
      id: 'changeRequests',
      href: '/bank-account-update-requests',
      title: t('navigation.change-requests'),
      icon: 'receiptInvoice',
    });
  }

  return items;
};

const useNavigationItems = () => {
  const fees = useFeesMenuItems();
  const finance = useFinanceMenuItems();
  const other = useOtherNavigationItems();

  return [...other, ...fees, ...finance];
};

export const NavigationSideBar = (props: { expanded: boolean; setExpanded: (b: boolean) => void }) => {
  const items = useNavigationItems();
  const { pathname } = useLocation();

  const [expandedItem, setExpandedItem] = useState<string | null>(null);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const setExpandedItemOnPageLoad = useCallback(() => {
    const itemsWithSubMenu = items.filter(i => !!i.subMenuItems);
    const selectedSubMenu = itemsWithSubMenu.find(i => {
      return i.subMenuItems?.find(it => it.href && pathname.startsWith(it.href));
    });

    setExpandedItem(selectedSubMenu?.id ?? null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const setSelectedItemOnPageLoad = useCallback(() => {
    const itemsAndSubMenuItems = items.flatMap(i => [i, ...(i.subMenuItems ?? [])]);
    const selectedItem = itemsAndSubMenuItems.find(i => i.href && pathname.startsWith(i.href));

    setSelectedItem(selectedItem?.id ?? null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    setExpandedItemOnPageLoad();
    setSelectedItemOnPageLoad();
  }, [pathname, setExpandedItemOnPageLoad, setSelectedItemOnPageLoad]);

  return (
    <Fixed>
      <NavigationBar
        expanded={props.expanded}
        selectedItem={selectedItem}
        expandedItem={expandedItem}
        defaultNavigationExpanded={true}
        onExpandItem={(id: string | null) => {
          setExpandedItem(id);
        }}
        onSelectItem={(id: string | null) => {
          setSelectedItem(id);
          clearSearchSessionStorage();
        }}
      >
        <NavigationHeader
          showLogo
          showExpandToggle
          onExpandClick={() => {
            props.setExpanded(!props.expanded);
          }}
        />
        <NavigationSeparator noGutter />
        <NavigationTree fullHeight>
          {items.map(item =>
            item.subMenuItems ? (
              <NavigationItem key={item.id} itemId={item.id} title={item.title} icon={item.icon}>
                {item.subMenuItems?.map(subMenuItem => (
                  <NavigationItem
                    key={subMenuItem.id}
                    itemId={subMenuItem.id}
                    href={subMenuItem.href}
                    title={subMenuItem.title}
                  />
                )) ?? []}
              </NavigationItem>
            ) : (
              <NavigationItem key={item.id} itemId={item.id} href={item.href} title={item.title} icon={item.icon} />
            )
          )}
        </NavigationTree>
        <NavigationChild>
          <NavigationButton
            size={'s'}
            title={'Backoffice user'}
            icon={<ProfileImage>BO</ProfileImage>}
            targetContextMenu="profile-submenu"
            expandDirection={'top'}
          >
            <NavigationContextMenu direction={'top'} id="profile-submenu">
              <NavigationContextItem title={'Settings'} href={'/settings'} />
              <NavigationContextItem title={'Logout'} onClick={logOut} />
            </NavigationContextMenu>
          </NavigationButton>
        </NavigationChild>
      </NavigationBar>
    </Fixed>
  );
};
